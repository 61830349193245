import Script from 'next/script';

const GtmScript = ({ gtmId }: { gtmId: string }) => {
  if (!gtmId) {
    console.error('GTM ID is missing!');
  }

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`}
      />
      <Script id="gtm-init-script" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtmId}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
    </>
  );
};

export default GtmScript;
